<template>
  <div class="width_1920">
    <div class="back" @click="goBack">返回</div>
    <div class="top_title">
      <div class="text">{{ name }}项目成绩</div>
    </div>
    <div class="h19"></div>
    <div v-for="(item, index) in teamList" :key="index">
      <div :class="index == 0 ? 'label' : 'label1'">
        <div class="num">
          {{ item.rank }}
        </div>
        <img class="avator" :src="item.teamLogo" />
        <div class="w903">
          <div class="team_name">{{ item.teamName }}·</div>
          <div class="school_name">{{ item.schoolName }}</div>
        </div>
        <div class="line"></div>
        <div v-if="scoreType == 'time'" class="score">
          {{ formatMill1(item.timeScore) }}
        </div>
        <div v-else class="score">{{ item.timeScore }}</div>
        <div class="time">
          {{
            scoreType == "time"
              ? "时间"
              : scoreType == "number"
              ? "个数"
              : "点数"
          }}
        </div>
      </div>
    </div>
    <div class="h50"></div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      projectId: "",
      standId: "",
      name: "",
      scoreType: "",
      teamList: [],
      timer: null,
      processId: "",
    };
  },
  mounted() {
    this.projectId = this.$route.query.id;
    this.standId = this.$route.query.standId;
    this.processId = this.$route.query.processId;
    let that = this;
    that.getRankList();
    this.timer = setInterval(() => {
      setTimeout(() => {
        that.getRankList();
      }, 0);
    }, 1000);
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
  methods: {
    getRankList() {
      var that = this;
      let data = {
        standId: this.standId,
        projectReId: this.projectId,
        processId: this.processId,
      };
      that.api.s_sports.getSingleRankList(data).then((res) => {
        if (res.code == 200) {
          this.teamList = res.result.teamScoreDtos;
          this.name = res.result.projectName;
          this.scoreType = res.result.scoreType;
        }
      });
    },
    goBack() {
      this.$router.go(-1);
    },
    formatMill1(msd) {
      if (!msd) {
        return "";
      }
      var str = "";
      var time = parseFloat(msd) / 1000;
      if (null != time && "" != time) {
        var millsecond = this.formatMillNumber(
          parseFloat(msd) - parseInt(time) * 1000
        );
        if (time < 60) {
          str = parseInt(time) + "″" + millsecond;
        } else if (time >= 60 && time < 60 * 60) {
          var sec = parseInt(time) - parseInt(time / 60) * 60;
          if (sec < 10) {
            str = parseInt(time / 60) + "′0" + sec + "″" + millsecond;
          } else {
            str = parseInt(time / 60) + "′" + sec + "″" + millsecond;
          }
        }
      }
      return str;
    },
    formatMillNumber(msd) {
      var str = "00";
      if (msd < 10) {
        str = "0" + msd;
      } else {
        str = msd;
      }
      return str;
    },
  },
};
</script>
<style scoped lang="scss">
.display-flex {
  display: flex;
  justify-content: space-between;
  padding: 20px;
}
.width_1920 {
  width: 100%;
  height: 1080px;
  background: #15151e;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.back {
  font-size: 34px;
  font-family: FZZDHJW;
  font-weight: 400;
  color: #fff;
  position: fixed;
  top: 30px;
  left: 40px;
  cursor: pointer;
}
.column {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.top_title {
  width: 1125px;
  height: 146px;
  background: #ffffff;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  .text {
    font-size: 64px;
    font-family: FZZDHJW;
    font-weight: 400;
    color: #15151e;
    line-height: 57px;
  }
}
.label {
  width: 1807px;
  height: 100px;
  display: flex;
  flex-direction: row;
  background: #202035;
  border-radius: 10px;
  align-items: center;
  margin-bottom: 6px;
  .num {
    width: 35px;
    font-size: 44px;
    font-family: Bungee;
    font-weight: 400;
    color: #ffffff;
    margin-left: 75px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .avator {
    width: 60px;
    height: 60px;
    min-width: 60px;
    margin-left: 84px;
    border-radius: 50%;
  }
  .team_name {
    font-size: 39px;
    font-family: Alibaba PuHuiTi;
    font-weight: 600;
    color: #e9e9e9;
    margin-left: 20px;
  }
  .school_name {
    font-size: 39px;
    font-family: Alibaba PuHuiTi;
    font-weight: 400;
    color: #e9e9e9;
  }
  .score {
    width: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 59px;
    font-family: FZPSCH;
    font-weight: normal;
    color: #03ffff;
    margin-left: 100px;
  }
  .time {
    font-size: 28px;
    font-family: FZZDHJW;
    font-weight: 400;
    color: #03ffff;
    margin-left: 58px;
  }
}
.w903 {
  width: 900px;
  display: flex;
}
.line {
  width: 6px;
  height: 60px;
  background: #ed6d34;
}
.label1 {
  width: 1807px;
  height: 100px;
  display: flex;
  flex-direction: row;
  background: #ffffff;
  border-radius: 10px;
  align-items: center;
  margin-bottom: 6px;
  .num {
    width: 35px;
    font-size: 44px;
    font-family: Bungee;
    font-weight: 400;
    color: #15151e;
    margin-left: 75px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .avator {
    width: 60px;
    height: 60px;
    min-width: 60px;
    margin-left: 84px;
    border-radius: 50%;
  }
  .team_name {
    font-size: 39px;
    font-family: Alibaba PuHuiTi;
    font-weight: 600;
    color: #15151e;
    margin-left: 20px;
  }
  .school_name {
    font-size: 39px;
    font-family: Alibaba PuHuiTi;
    font-weight: 400;
    color: #15151e;
  }
  .score {
    width: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 59px;
    font-family: FZPSCH;
    font-weight: normal;
    color: #15151e;
    margin-left: 100px;
  }
  .time {
    font-size: 28px;
    font-family: FZZDHJW;
    font-weight: 400;
    color: #15151e;
    margin-left: 58px;
  }
}
.h19 {
  height: 19px;
}
.h50 {
  height: 60px;
}
</style>
